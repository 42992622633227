<template>
    <div class="manager-index index-page" :class="$route.params.disease">
        <div class="doctor-page doctor-login">
            <div class="banner">
                <img src="/doctor-login.png" alt="pic" width="100%" />
            </div>
            <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                class="code"
                inline
            >
                <div>
                    <div class="label">帳號</div>
                    <el-form-item prop="email">
                        <el-input
                            class="code-input"
                            v-model="ruleForm.email"
                            placeholder="請輸入帳號"
                        ></el-input>
                    </el-form-item>
                </div>
                <div>
                    <div class="label">密碼</div>
                    <el-form-item prop="password">
                        <el-input
                            class="code-input"
                            v-model="ruleForm.password"
                            placeholder="請輸入密碼"
                        ></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div class="confirm">
                <button-wrapper
                    type="navigation"
                    color="normal"
                    @click="submitForm"
                >
                    確定
                </button-wrapper>
            </div>
            <div class="empty"></div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
    async beforeRouteEnter(to, from, next) {
        const isInIframe = window.frames.length !== window.parent.frames.length;
        if (!isInIframe) {
            next((vm) => {
                window.location.href = `${window.location.origin}/${vm.$route.params.disease}/sales/case`;
            });
        } else next();
    },
    created() {
        const TOKEN = window.localStorage.token;
        const DISEASE = this.$route.params.disease;
        if (TOKEN) this.jwtLogin(TOKEN, DISEASE);
        else this.setDisease(DISEASE);
    },
    data() {
        return {
            ruleForm: {
                email: '',
                password: '',
            },
            rules: {
                email: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        ...mapMutations({
            setDisease: 'api/setDisease',
            setToken: 'api/setToken',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.onVarify();
                    return true;
                }
                return false;
            });
        },
        async jwtLogin(token, disease) {
            this.setToken(token);
            this.setDisease(disease);
            const PAYLOAD = {
                category: 'Auth',
                function: 'verifyJwtToken',
            };
            const RES = await this.apiClient(PAYLOAD);
            if (!RES) {
                window.localStorage.clear();
                this.setToken(undefined);
                return;
            }
            this.setLocalData(RES);
        },
        async onVarify() {
            const DATA = {
                email: this.ruleForm.email,
                password: this.ruleForm.password,
            };
            const PAYLOAD = {
                category: 'Auth',
                function: 'CLMLogin',
                data: DATA,
            };
            const RES = await this.apiClient(PAYLOAD);
            this.setLocalData(RES);
        },
        setLocalData(res) {
            window.localStorage.setItem('token', res.data.token);
            if (window && window.parent) {
                const message = { command: 'setToken', token: res.data.token };
                window.parent.postMessage(message, '*');
            }
            this.$router.push({ name: 'Sales_Case_List' });
        },
    },
};
</script>

<style lang="scss" scoped>
.doctor-login {
    .banner {
        max-width: 400px;
    }
    .code {
        margin: 50px 0 40px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        > * {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        > :last-child {
            margin-bottom: 0;
        }
        .label {
            margin-right: 20px;
        }
        &-input {
            width: 350px;
        }
    }
    .empty {
        margin-top: 20px;
        height: 28px;
    }
}
</style>
